// スムーススクロール
import smoothScroll from './component/smoothScroll'
smoothScroll()

// ヘッダーアニメーション
import header from './component/header'
header()

// スクロールフェードアニメーション
import scrollFade from './component/scrollFade'
scrollFade()

// MVに画面高さ
import windowsHeight from './component/windowsHeight'
windowsHeight()



// ヘッダーメニュー
//import menu from './component/menu'
//menu()
