/**
 * ヘッダーアニメーション
 */

export default function () {

  var header = document.getElementById('header');
  window.addEventListener('scroll', () => {
    var scrollValue = window.pageYOffset;

    if (scrollValue > 0) {
      header.classList.add('js-scroll');
    } else {
      header.classList.remove('js-scroll');
    }
  });




  const headerHeightFunc = () => {
    let headerElem = document.getElementById('header-spMenu');
    let wh = window.innerHeight;

    if (window.matchMedia("(max-width:999px)").matches) {
      headerElem.style.height = wh + 'px';
    } else {
      headerElem.style.height = 'auto';
    }
  }

  window.addEventListener('load', headerHeightFunc);
  window.addEventListener('resize', headerHeightFunc);






  $(`.c-header___navItem a`).on(`click`, function () {
    $(`.js-spMenuToggleTarget`).removeClass(`is-open`);
    $(`#header`).removeClass(`is-active`);
  })
}




