/**
 * Jump.jsを使用したスムーススクロール
 * aタグのhref属性にtargetのidを設定する 例. href="#hoge"
 * data-scroll-false属性を設定するとスムーススクロールは行わない
 * DOM読み込み後に実行
 * https://github.com/callmecavs/jump.js
 */
export default function () {

  //スムーズスクロール
  jQuery(function () {
    // ★　任意のズレ高さピクセル数を入力　↓
    var headerHight = 100;
    // #で始まるアンカーをクリックした場合に処理
    jQuery('a[href^="#"]').click(function () {
      // スクロールの速度
      var speed = 400; // ミリ秒
      // アンカーの値取得
      var href = jQuery(this).attr("href");
      // 移動先を取得
      var target = jQuery(href == "#" || href == "" ? 'html' : href);
      // 移動先を数値で取得
      var position = target.offset().top - headerHight; // ※　-headerHightでズレの処理
      // スムーズスクロール
      jQuery('body,html').animate({ scrollTop: position }, speed, 'swing');
      return false;
    });
  });


}
