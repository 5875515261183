/**
 * 高さ取得
 */


/**
 * 高さをいっぱいに設定
 */


export default function () {
  document.addEventListener(`DOMContentLoaded`, () => {
    mvHeightFunc()

    // リサイズ
    window.addEventListener(`resize`, () => {
      mvHeightFunc()
    })
  })
}


/**
 * ウィンドウ高さリセット
 */
function mvHeightFunc() {
  let mvElem = document.getElementById('topMv');
  let wh = window.innerHeight;
  if ($('#topMv').length) {
    mvElem.style.height = wh + `px`;
  }
}



